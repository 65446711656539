import { format } from 'date-fns';

import BannerCard from '@/components/BannerCard';
import { useMeterFittingAppointment } from '@/hooks/usage/useMeterFittingAppointment';
import Meter from '@/public/images/icons/Meter';

const MeterFittingBannerCard = () => {
  const { data, isLoading, isError } = useMeterFittingAppointment();

  if (isLoading || isError || !data) {
    return null;
  }

  const appointmentStartDate = new Date(data.scheduledAt!);
  const appointmentEndDate = new Date(data.scheduledEndAt!);
  const appointmentDay = format(appointmentStartDate, 'EEEE d MMMM yyyy');

  const formattedAppointment = `${appointmentDay} ${format(
    appointmentStartDate,
    'h:mma'
  )} - ${format(appointmentEndDate, 'h:mma')}`;

  const meterFittingProvider = process.env.NEXT_PUBLIC_METER_FITTING_PROVIDER;
  const meterFittingContactNumber =
    process.env.NEXT_PUBLIC_METER_FITTING_CONTACT_NUMBER;

  return (
    <BannerCard
      headingText="Your upcoming meter installation"
      bodyContent={
        <>
          Your installation is booked for {formattedAppointment}.{' '}
          {meterFittingProvider && meterFittingContactNumber && (
            <>
              If you need to amend or cancel, please get in touch with{' '}
              {meterFittingProvider} on {meterFittingContactNumber}.
            </>
          )}
        </>
      }
      icon={<Meter height={48} width={48} filled />}
    />
  );
};

export default MeterFittingBannerCard;
