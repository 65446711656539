import { ReactNode } from 'react';
import { Card, Grid, Stack, Typography } from '@krakentech/coral';

type BannerCardPropType = {
  headingText: string;
  bodyContent: ReactNode;
  icon?: ReactNode;
};

const BannerCard = ({ headingText, bodyContent, icon }: BannerCardPropType) => {
  return (
    <Card>
      <Grid
        alignItems="center"
        {...(icon && { gap: 'md' })}
        templateColumns="1 1fr"
        md={{
          templateColumns: 'auto 1fr',
        }}
      >
        <Grid.Item>
          <Stack justifyContent="center">{icon}</Stack>
        </Grid.Item>
        <Grid.Item>
          <Stack direction="vertical" gap="xsSm">
            <Typography variant="h2">{headingText}</Typography>
            <Typography>{bodyContent}</Typography>
          </Stack>
        </Grid.Item>
      </Grid>
    </Card>
  );
};

export default BannerCard;
