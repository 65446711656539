import { useCoralTheme } from '@krakentech/coral';

const Meter = ({
  width,
  height,
  filled = false,
}: {
  width: number;
  height: number;
  filled?: boolean;
}) => {
  const { color } = useCoralTheme();
  const fill = filled ? color.primary.main : 'none';

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 110 110"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
    >
      <path
        d="M66.0602 66.2027L66.059 66.2049C64.2174 69.6867 63.2551 73.5681 63.2551 77.5C63.2551 81.0627 64.027 84.4481 65.4133 87.4939C59.475 91.2966 52.4174 93.5 44.8367 93.5C23.6674 93.5 6.5 76.2711 6.5 55C6.5 33.7289 23.6674 16.5 44.8367 16.5C61.0281 16.5 74.888 26.5832 80.5005 40.8463C79.4035 41.7333 78.4667 42.8417 77.7778 44.1402C77.7777 44.1404 77.7776 44.1406 77.7774 44.1409L66.0602 66.2027ZM84.8311 47.9067L84.8336 47.902C85.2876 47.0398 86.1899 46.5 87.1633 46.5C88.1366 46.5 89.0389 47.0398 89.4929 47.902L89.4954 47.9067L101.213 69.9692L101.215 69.9731C102.451 72.2839 103.092 74.8686 103.092 77.5C103.092 86.3492 95.9615 93.5 87.1633 93.5C78.3651 93.5 71.2347 86.3492 71.2347 77.5C71.2347 74.8686 71.8755 72.2839 73.1115 69.9731L73.1135 69.9692L84.8311 47.9067ZM36.3776 45V40C36.3776 36.4117 33.4865 33.5 29.898 33.5C26.3094 33.5 23.4184 36.4117 23.4184 40V45C23.4184 48.5883 26.3094 51.5 29.898 51.5C33.4865 51.5 36.3776 48.5883 36.3776 45ZM51.3163 45V40C51.3163 36.4117 48.4253 33.5 44.8367 33.5C41.2482 33.5 38.3571 36.4117 38.3571 40V45C38.3571 48.5883 41.2482 51.5 44.8367 51.5C48.4253 51.5 51.3163 48.5883 51.3163 45ZM66.2551 45V40C66.2551 36.4117 63.3641 33.5 59.7755 33.5C56.187 33.5 53.2959 36.4117 53.2959 40V45C53.2959 48.5883 56.187 51.5 59.7755 51.5C63.3641 51.5 66.2551 48.5883 66.2551 45Z"
        stroke={color.primary.main}
        strokeWidth="3"
      />
    </svg>
  );
};

export default Meter;
